<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-row class="mt-8">
        <v-col cols="12">
          <div>
            <v-card class="card-shadow border-radius-xl">
              <div class="card-header-padding">
                <div class="d-flex align-center">
                  <div>
                    <h5 class="font-weight-bold text-h5 text-typo mb-0">
                      Knock down
                    </h5>
                    <!-- <p class="text-sm text-body mb-0">
                      Possible states of a document
                    </p> -->
                  </div>
                </div>
              </div>

              <v-card-text class="px-0 py-0">
                <v-card class="card-shadow border-radius-xl">
                  <v-form ref="frm">
                    <v-card-text class="card-padding">
                      <v-row>
                        <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                          <label
                            class="text-md text-typo font-weight-bolder ms-1"
                            ><span class="red--text"><strong>* </strong></span
                            >Season</label
                          >

                          <v-select
                            :items="season"
                            item-value="id"
                            hide-details="auto"
                            v-model="editedItem.season"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mt-3
                              mb-0
                            "
                            outlined
                            height="46"
                            @change="filterSeasonSale()"
                            single-line
                            :rules="[(v) => !!v || 'Season is required']"
                            placeholder="Select Season"
                          >
                            <template v-slot:selection="{ item }">
                              <v-text
                                name="ss_name"
                                label
                                color="bg-default"
                                class="py-1 px-2 my-0"
                              >
                                <span class="text-caption ls-0">{{
                                  item.name
                                }}</span>
                              </v-text>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-text
                                name="ss_name"
                                label
                                color="bg-default"
                                class="py-1 px-2 my-0"
                              >
                                <span class="text-caption ls-0">{{
                                  item.name
                                }}</span>
                              </v-text>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="6" md="3" xs="12" lg="3">
                          <label
                            class="text-md text-typo font-weight-bolder ms-1"
                            ><span class="red--text"><strong>* </strong></span
                            >Sale No</label
                          >

                          <v-select
                            :items="seasonSale"
                            item-value="id"
                            hide-details="auto"
                            v-model="season_sale_id"
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              border-radius-md
                              select-style
                              mt-3
                              mb-0
                            "
                            outlined
                            height="46"
                            single-line
                            :rules="[(v) => !!v || 'Sale No is required']"
                            placeholder="Select Sale No"
                          >
                            <template v-slot:selection="{ item }">
                              <v-text
                                name="ss_name"
                                label
                                color="bg-default"
                                class="py-1 px-2 my-0"
                              >
                                <span class="text-caption ls-0">{{
                                  item.season_sale
                                }}</span>
                              </v-text>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-text
                                name="ss_name"
                                label
                                color="bg-default"
                                class="py-1 px-2 my-0"
                              >
                                <span class="text-caption ls-0">{{
                                  item.season_sale
                                }}</span>
                              </v-text>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions class="card-padding d-flex justify-end">
                      <v-btn
                        @click="close"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-secondary
                          bg-light
                          py-3
                          px-6
                        "
                        >Cancel</v-btn
                      >

                      <v-btn
                        @click="search"
                        elevation="0"
                        :ripple="false"
                        height="46"
                        dark
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-primary
                          bg-success
                          py-3
                          px-6
                        "
                        >Search</v-btn
                      >
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-card-text>
              <v-card-text class="px-0 py-0" v-if="knockdownList.length > 0">
                <v-data-table
                  :headers="headers"
                  :items="knockdownList"
                  item-key="id"
                  class="table"
                  mobile-breakpoint="0"
                  :footer-props="{
                    'items-per-page-options': [100, 200, 500, -1],
                  }"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Knockdown list</v-toolbar-title>

                      <v-spacer></v-spacer>
                      <v-btn
                        @click="download"
                        elevation="0"
                        :ripple="false"
                        height="43"
                        dark
                        class="
                          font-weight-bold
                          text-capitalize
                          btn-ls btn-primary
                          bg-success
                          py-3
                          px-6
                        "
                        >Download
                      </v-btn>
                    </v-toolbar>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import axios from "axios";
import api from "../api";
import apiMaster from "../../../master/master-get-api";
export default {
  name: "Knock down",
  data() {
    return {
      overlay: false,
      season: [],
      seasonSale: [],
      season_sale_id: 0,
      page: 1,
      pageCount: 0,
      knockdownList: [],
      editedItem: {
        season: 0, // not used in API- just to filter Sale No
      },
      headers: [
        {
          text: "Lot No",
          value: "lot_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
        },
        {
          text: "Mark",
          value: "mark",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
        },
        {
          text: "Grade",
          value: "grade",
          align: "center",
          class: "text-secondary font-weight-bolder opacity-10",
        },
        {
          text: "Quantity",
          value: "quantity",
          align: "center",
          class: "text-secondary font-weight-bolder opacity-10",
        },
        {
          text: "Price",
          value: "price",
          align: "center",
          class: "text-secondary font-weight-bolder opacity-10",
        },
        {
          text: "Packages",
          value: "pacakges",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
        },
        {
          text: "Invoice",
          value: "invoice",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
        },
        {
          text: "Winning buyer",
          value: "winning_buyer",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
        },
      ],
    };
  },

  created() {
    this.initialize();
  },
  methods: {
    validate() {
      return this.$refs.frm.validate();
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        showConfirmButton: true,
        icon: "error",
      });
    },

    async initialize() {
      this.overlay = true;
      try {
        this.season = await apiMaster.getSeason();
        this.seasonSale = await apiMaster.getSeasonSale();
        this.overlay = false;
      } catch (err) {
        this.showErrorAlert(err);
      }
    },
    async filterSeasonSale() {
      this.overlay = true;
      this.editedItem.seasonSale = 0;
      this.seasonSale = await apiMaster.getSeasonSaleBySeason(
        this.editedItem.season
      );
      this.overlay = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.$refs.frm.reset();
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async search() {
      if (this.validate()) {
        this.overlay = true;
        try {
          this.knockdownList = await api.getByFilter(this.season_sale_id);
          if (this.knockdownList.length < 1) {
            this.showWarningAlert("No data found");
          }
        } catch (err) {
          this.showErrorAlert(err.message);
        } finally {
          this.overlay = false;
        }
      }
    },
    async download() {
      if (this.validate()) {
        this.overlay = true;
        try {
          const data = await api.downloadList(this.season_sale_id);
          this.forceFileDownload(data, "Knockdown.xlsx");
        } catch (err) {
          this.showErrorAlert(err.message);
        } finally {
          this.overlay = false;
        }
      }
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", title);
      document.body.appendChild(link);
      link.click();
    },
  },

  computed: {
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>
