import axios from "axios";
const API = "auction-knockdown";

export default {
  async get() {
    return await axios
      .get(API)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  create(item) {
    return axios
      .post(API, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  resend(item) {
    return axios
      .post(API + `/resend-file`, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  confirm(item) {
    return axios
      .post(API + `/confirm-knockdown-file`, item)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  delete(id) {
    return axios
      .delete(API + `/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getByFilter(data) {
    return await axios
      .get(API + "/knock-down-filter/" + data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async downloadList(data) {
    // return await axios
    //   .get(API + "/knock-down-download/" + data)
    //   .then((res) => {
    //     return res;
    //   })
    //   .catch((err) => {
    //     throw new Error(err.response.data.message);
    //   });

    return axios({
      method: "get",
      url: API + "/knock-down-download/" + data,
      responseType: "arraybuffer",
    })
      .then((res) => {
        // alert(res);
        // this.forceFileDownload(res);
        return res;
      })
      .catch((err) => {
        this.report = [];
        console.log(err);
      });
  },
};
