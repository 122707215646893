import axios from "axios";

export default {
  async getAuctionCenter() {
    return await axios
      .get("auction-center")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getSeason() {
    return await axios
      .get("season")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getSeasonSale() {
    return await axios
      .get("season-sale")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getState() {
    return await axios
      .get("state")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getSeasonSaleBySeason(season_id) {
    return await axios
      .get("season-sale/sale-by-season/" + season_id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getSeasonSaleBySeasonAndAuctionCenter(season_id, auction_center_id) {
    return await axios
      .get(
        `season-sale/sale-by/season/auction_center?season_id=${season_id}&auction_center_id=${auction_center_id}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getTeaType() {
    return await axios
      .get("tea-type")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getCategory() {
    return await axios
      .get("category")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getGrade() {
    return await axios
      .get("grade")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getMark() {
    return await axios
      .get("marks")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getBrokers() {
    return await axios
      .get("brokers")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getBrokersWithAuctionCenter(id) {
    return await axios
      .get("brokers/auction-center/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getRole() {
    return await axios
      .get("role")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getSeasonByVendor(vendor_id) {
    return await axios
      .get(`private-vendor-offer/vendor-offer-season-by-vendor-id/${vendor_id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getOffersByvendorSeason(vendor_id, season_id) {
    return await axios
      .get(
        `private-vendor-offer/get-offers-by-vendor-and-season?season_id=${season_id}&vendor_id=${vendor_id}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getPrivateVendors() {
    let type = "PRIVATE";
    return await axios
      .get("vendors/vendor/by/type/" + type)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
};
